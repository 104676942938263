import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax';

import Plyr from '../components/plyr'


//import { Link } from "../utils/link"
import { Link } from "gatsby"
import Fade from 'react-reveal/Fade'

import Seo from '../components/seo'

import Logo from '../assets/images/logo.svg'
import Badge from '../assets/images/ico-badge.inline.svg'
import Grid from '../assets/images/ico-grid.inline.svg'

class AboutTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />

        <section id="list" className="about-list">
        { page.acf.aboutImages && page.acf.aboutImages.map((el, i) => (
            <div key={i} className='about-list__item'>

                {i === 1 &&
                  <div className='about-list__ico-badge'><Badge /></div>
                }

                {i === 2 &&
                  <div className='about-list__ico-badge two'><Badge /></div>
                }

                {i === 5 &&
                  <div className='about-list__ico-badge three'><Badge /></div>
                }

                {i === 3 &&
                  <div className='about-list__ico-grid one'><Grid /></div>
                }

                {i === 8 &&
                  <div className='about-list__ico-badge four'><Badge /></div>
                }

                {i === 10 &&
                  <div className='about-list__ico-grid'><Grid /></div>
                }

                {i === 12 &&
                  <div className='about-list__ico-badge four'><Badge /></div>
                }

                <div className={'item__' + i }>

                  {i === 1 &&
                    <div className='about-list__description mobile' dangerouslySetInnerHTML={{ __html: page.acf.description  }} />
                  }

                  <Parallax translateY={[-15, 20]}>
                    <div className='first-img'>
                      <GatsbyImage className='about-list__image' loading='lazy' image={getImage(el.image?.localFile)} alt='Origi' />
                    </div>
                  </Parallax>

                  {i === 1 &&
                    <div>
                      <Parallax translateY={[-15, 20]}>
                        <div className='about-list__description desktop' dangerouslySetInnerHTML={{ __html: page.acf.description  }} />
                      </Parallax>


                      <Plyr
                      type='vimeo'
                      ref='player'
                      videoId={ page.acf.vimeoOne }
                      autoplay={ true }
                      controls={ ['play-large', 'progress', 'mute', 'volume'] }
                      urls={{
                        vimeo: {
                          api: 'https://noembed.com/embed?url={0}'
                        }
                      }}
                      />

                    </div>

                  }
                </div>
            </div>
          )) }

          <div className='about-list__item'>
            <div className='item__10'>
            <Plyr
              type='vimeo'
              ref='player'
              videoId={ page.acf.vimeoTwo }
              autoplay={ true }
              controls={ ['play-large', 'progress', 'mute', 'volume'] }
              urls={{
                vimeo: {
                  api: 'https://noembed.com/embed?url={0}'
                }
              }}
              />
            </div>
          </div>

          </section>

      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        description
        vimeoOne
        vimeoTwo
        aboutImages {
          fieldGroupName
          type
          vimeoVideo
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  width: 1800
                  formats: [AUTO, WEBP]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
  }
`

export default AboutTemplate
